import React from "react";
import { BsCaretRightFill } from "react-icons/bs";

const Services = () => {
  return (
    <div>
      <div className="2xl:mt-20 mt-24 pb-10 md:px-20 sm:px-10 px-5">
        <h1 className="2xl:text-6xl text-3xl sm:text-4xl xl:text-4xl lg:text-5xl font-bold text-secondary text-center text_raleway">
          Our Services
        </h1>
        <div className="mt-20 pt-10 bg-[#f7f7f799] rounded-xl shadow px-6 py-10 ">
          <h4 className="text-primary pb-10 font-extrabold lg:text-4xl md:text-3xl sm:text-2xl text-lg text_raleway text-center">
            Supply, Installation & Commissioning of
          </h4>
          <div className="grid lg:grid-cols-2 sm:gap-1 md:gap-2 lg:gap-0">
            <div className="flex flex-col sm:gap-1 md:gap-2 xl:gap-3">
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  Foldable Knuckle Boom Crane
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  Stiff Boom Crane
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  Telescopic Boom Crane
                </h4>
              </div>
            </div>
            <div className="flex flex-col sm:gap-1 md:gap-2 xl:gap-3">
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  OFFSHORE, WORKBOATS, SAR
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  Electric Wire Rope Hoist
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  ART Manual Articulated Trolley
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 pt-10 bg-[#f7f7f799] rounded-xl shadow px-6 py-10 ">
          <h4 className="text-primary pb-10 font-extrabold lg:text-4xl md:text-3xl sm:text-2xl text-lg text_raleway text-center">
            Our Global Partners
          </h4>
          <div className="grid lg:grid-cols-2 sm:gap-1 md:gap-2 lg:gap-0">
            <div className="flex flex-col sm:gap-1 md:gap-2 xl:gap-3">
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  Palfinger Marine, Austria
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  NRG MARINE, UK
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  CHASE CORPORATION, UK
                </h4>
              </div>
            </div>
            <div className="flex flex-col sm:gap-1 md:gap-2 xl:gap-3">
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  SeaComAir Company, Germany
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  TURBOTECNICA ENGINEERING SPA, ITALY
                </h4>
              </div>
              <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
                <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
                <h4 className="text-black font-bold xl:text-2xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
                  STAHL CRANE, Germany
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
