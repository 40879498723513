import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
// import { FiPhoneCall } from "react-icons/fi";
// import { MdMailOutline } from "react-icons/md";
// import { BiMap } from "react-icons/bi";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Contact = () => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // EmailJs Login
  // Email: vadoh68382@wifame.com
  // Password: Abced1243#
  const sendEmail = (formData) => {
    emailjs
      .send("Techno Global", "TG Message", formData, "V8Z_q7nVaapM4JCQr")
      .then(
        () => {
          toast.success("Message sent successfully!", {
            position: "bottom-right",
          });
          reset();
        },
        () => {
          toast.error("Error! Send again.", {
            position: "bottom-right",
          });
        }
      );
  };

  return (
    <div
      id="contact"
      className="rounded-3xl py-10 lg:mx-20 sm:mx-10 mx-5 w-[unset]"
    >
      <p className="2xl:pb-2 md:pb-0.5 pb-0 text-secondary text-center 2xl:text-3xl md:text-xl sm:text-lg font-bold text_raleway">
        Get in touch
      </p>
      <h1 className="2xl:text-6xl text-3xl sm:text-4xl font-bold text-primary text-center text_raleway">
        Hey! Let's Talk
      </h1>
      <div className="grid xl:pt-14 pt-10 xl:px-24 sm:px-10 px-5">
        <div className="card w-full bg-base-100">
          <div className="card-body">
            <form onSubmit={handleSubmit(sendEmail)}>
              <div className="form-control">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  className="input focus:outline-offset-0 text-base bg-accent text-[#252525] font-medium text_raleway"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "*Enter your name",
                    },
                  })}
                />
                <label className="label pt-0 pb-2.5">
                  {errors.name?.type === "required" && (
                    <span className="label-text-alt text-error">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control">
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  className="input focus:outline-offset-0 text-base bg-accent text-[#252525] font-medium text_raleway"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "*Enter an email",
                    },
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "*Please enter a valid email",
                    },
                  })}
                />
                <label className="label pt-0 pb-2.5">
                  {errors.email?.type === "required" && (
                    <span className="label-text-alt text-error">
                      {errors.email.message}
                    </span>
                  )}
                  {errors.email?.type === "pattern" && (
                    <span className="label-text-alt text-error">
                      {errors.email.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control">
                <input
                  name="phone"
                  id="phone"
                  type="tel"
                  placeholder="Phone"
                  className="input focus:outline-offset-0 text-base bg-accent text-[#252525] font-medium text_raleway"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "*Enter a phone number",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "*Please enter a valid phone number",
                    },
                  })}
                />
                <label className="label pt-0 pb-2.5">
                  {errors.phone?.type === "required" && (
                    <span className="label-text-alt text-error">
                      {errors.phone.message}
                    </span>
                  )}
                  {errors.phone?.type === "pattern" && (
                    <span className="label-text-alt text-error">
                      {errors.phone.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control">
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  placeholder="Your Message"
                  className="textarea focus:outline-offset-0 text-base bg-accent text-[#252525] font-medium text_raleway"
                  {...register("message", {
                    required: {
                      value: true,
                      message: "*Enter your message",
                    },
                  })}
                />
                <label className="label pt-0 pb-2.5">
                  {errors.message?.type === "required" && (
                    <span className="label-text-alt text-error">
                      {errors.message.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control mt-3">
                <input
                  className="btn text-white normal-case font-semibold text-base bg-secondary border-none hover:border-none hover:bg-[#0e1f51d9] shadow-xl px-8 py-2.5 text_raleway"
                  type="submit"
                  value="Send Now"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
