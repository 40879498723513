import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import com3 from "../Images/Projects/CompletedProjects/(3).jpg";
import com8 from "../Images/Projects/CompletedProjects/(8).jpg";
import com10 from "../Images/Projects/CompletedProjects/(10).jpg";
import com16 from "../Images/Projects/CompletedProjects/(16).jpg";
import com17 from "../Images/Projects/CompletedProjects/(17).png";
import com18 from "../Images/Projects/CompletedProjects/(18).png";

import on1 from "../Images/Projects/OngoingProjects/(1).jpg";
import on7 from "../Images/Projects/OngoingProjects/(7).jpg";
import on14 from "../Images/Projects/OngoingProjects/(14).jpg";
import on19 from "../Images/Projects/OngoingProjects/(19).png";
import on20 from "../Images/Projects/OngoingProjects/(20).png";
import on21 from "../Images/Projects/OngoingProjects/(21).png";

const Projects = () => {
  const completedProject = [
    {
      src: com3,
      text: "Design and Construction of Steel Structure Artifacts Model (LXW=50'X10') of LCT BS for Museum Display.",
    },
    {
      src: com16,
      text: "Design and Construction of Steel Structure Scale Model (LXW=85'X10') of BNS Nabajatra (Sub-Marine) for Museum Display.",
    },
    {
      src: com17,
      text: "2 Units Palfinger Foldable Knuckle Boom Marine Crane, Capacity: SWL 2T @ 8M Outreach ",
    },
    {
      src: com10,
      text: "Design and Construction of Steel Structure Scale Model (LXW=80'X10') of BNS Bangabandhu (Frigate) for Museum Display.",
    },
    {
      src: com18,
      text: "2 Units Palfinger Foldable Knuckle Boom Marine Crane, Capacity: SWL 2.5T @ 8M Outreach Client: Radiant Shipyard Limited",
    },
    {
      src: com8,
      text: "2 Units Palfinger Foldable Knuckle Boom Marine Crane, Capacity: SWL 2.5T @ 8M Outreach",
    },
  ];

  const ongoingProjects = [
    {
      src: on19,
      text: "Design and Construction of Steel Structure Ship Theme Café for Bangladesh Airforce.",
    },
    {
      src: on20,
      text: "Design and Construction of 2-Storied Multi-Purpose Shed at Tejgaon I/A",
    },
    {
      src: on21,
      text: "Design and Construction of 2-Storied Multi-Purpose Shed at Tejgaon I/A",
    },
    {
      src: on1,
      text: "",
    },
    {
      src: on7,
      text: "",
    },
    {
      src: on14,
      text: "",
    },
  ];

  return (
    <div className="2xl:mt-20 mt-24 pb-10 md:px-44 sm:px-10 px-5">
      <h1 className="2xl:text-6xl text-3xl sm:text-4xl xl:text-4xl lg:text-5xl font-bold text-primary text-center text_raleway">
        Our Projects
      </h1>
      <p className="pt-3 pb-4 text-secondary text-2xl font-bold text_raleway text-center">
        Completed Projects
      </p>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {completedProject.map((completedProject, i) => (
            <>
              <img
                key={i}
                src={completedProject.src}
                style={{ width: "100%", display: "block" }}
                alt=""
                className="px-3 py-0.5"
              />
              <p className="pb-2 text-center text-sm text-base-400">
                {completedProject.text}
              </p>
            </>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <p className="pt-10 pb-4 text-secondary text-2xl font-bold text_raleway text-center">
        Ongoing Projects
      </p>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {ongoingProjects.map((ongoingProject, i) => (
            <>
              <img
                key={i}
                src={ongoingProject.src}
                style={{ width: "100%", display: "block" }}
                alt=""
                className="px-3 py-0.5"
              />
              <p className="pb-2 text-center text-sm text-base-400">{ongoingProject.text}</p>
            </>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <p className="text-center text-sm text-base-400">Construction of Glass Roof Along With Necessary Steel and Aluminum Structure for Atrium of National Art Gallery at Bangladesh Shilpakala Academy, Dhaka</p>
    </div>
  );
};

export default Projects;
