import React from "react";
import { Link } from "react-router-dom";
import Contact from "../Components/Contact";
import Team from "../Components/Team";
import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { BiMap } from "react-icons/bi";
import { BsCaretRightFill } from "react-icons/bs";

const AboutUs = () => {
  return (
    <>
      <div className="bg-[#f2f3f6]  mb-20 rounded-3xl md:py-20 sm:py-14 pt-10 pb-6  shadow-2xl md:mx-20 sm:mx-12 mx-5 md:mt-16 sm:mt-14 mt-14 w-[unset]">
        <h1 className="2xl:text-6xl md:text-3xl text-3xl sm:text-4xl xl:text-4xl lg:text-5xl font-bold text-primary text-center text_raleway">
          About Us
        </h1>
        {/* <div className="md:pt-14 pt-10 lg:px-24 md:px-12 sm:px-8 px-4">
          <div className="card w-full bg-base-100">
            <div className="card-body justify-between">
              <h3 className="text-secondary font-medium text_raleway 2xl:text-2xl  sm:text-xl text-sm">
                <b>Techno Global Ltd. (TGL)</b> is an engineering and
                contracting company with specializations in industrial solution,
                process engineering, marine solution, ICT solution, shipbuilding
                and green energy solution. The company is a technology
                independent vendor and supplier of special industrial equipment
                and packages.
                <br/>
                <br/>
                TGL was established in early 2020 as aggregation
                of business entrepreneurs and professionals having experiences
                in diversified sectors, such as, industrial utility and process,
                ICT, marine and shipbuilding, construction and management of gas
                and oil process, thanks mainly to the contribution of 20-25
                years' experiences brought in by the partners from their
                respective fields of expertise.
                <br/>
                <br/>
                At TGL, we combine well-proven
                business principles with an innovative performance profile. The
                company dispose a network of engineers and manufacturers out of
                which it forms a unique and made-to-measure strategic alliance
                for every single contract, aiming to bring together the best
                practices and products.
              </h3>
            </div>
          </div>
        </div> */}
        <div className="grid 2xl:grid-cols-[2fr_1fr] xl:grid-cols-[1.5fr_1fr] lg:grid-cols-2 gap-10  xl:pt-14 pt-10 xl:px-24 sm:px-10 px-5">
          <div className="card w-full bg-base-100">
            <div className="card-body justify-between">
              <h3 className="text-secondary font-medium text_raleway 2xl:text-2xl sm:text-xl text-sm">
                <b>Techno Global Ltd. (TGL)</b> is an engineering and
                contracting company with specializations in industrial solution,
                process engineering, marine solution, ICT solution, shipbuilding
                and green energy solution. The company is a technology
                independent vendor and supplier of special industrial equipment
                and packages.
                <br />
                <br />
                TGL was established in early 2020 as aggregation of business
                entrepreneurs and professionals having experiences in
                diversified sectors, such as, industrial utility and process,
                ICT, marine and shipbuilding, construction and management of gas
                and oil process, thanks mainly to the contribution of 20-25
                years' experiences brought in by the partners from their
                respective fields of expertise.
                <br />
                <br />
                At TGL, we combine well-proven business principles with an
                innovative performance profile. The company dispose a network of
                engineers and manufacturers out of which it forms a unique and
                made-to-measure strategic alliance for every single contract,
                aiming to bring together the best practices and products.
              </h3>
            </div>
          </div>
          <div className="card w-full bg-base-100">
            <div className="card-body justify-between gap-12 lg:gap-0">
              <div className="flex flex-col 2xl:gap-6 xl:gap-3 gap-5">
                <div className="flex lg:gap-4 sm:gap-5 gap-3 items-center">
                  <div className="bg-accent sm:p-5 p-3 rounded-xl">
                    <FiPhoneCall className="2xl:text-5xl xl:text-4xl lg:text-2xl sm:text-5xl text-3xl text-primary" />
                  </div>
                  <div>
                    <h4 className="text-secondary 2xl:text-2xl xl:text-lg lg:text-base sm:text-xl font-bold text_raleway">
                      Call Anytime
                    </h4>
                    <p className="font-semibold 2xl:text-base lg:text-sm sm:text-lg text-[10px]">
                      +8801327417387
                    </p>
                  </div>
                </div>
                <div className="flex lg:gap-4 sm:gap-5 gap-3 items-center">
                  <div className="bg-accent sm:p-5 p-3 rounded-xl">
                    <MdMailOutline className="2xl:text-5xl xl:text-4xl lg:text-2xl sm:text-5xl text-3xl text-primary" />
                  </div>
                  <div>
                    <h4 className="text-secondary 2xl:text-2xl xl:text-lg lg:text-base sm:text-xl font-bold text_raleway">
                      Send Email
                    </h4>
                    <p className="font-semibold 2xl:text-base lg:text-sm sm:text-lg text-[10px]">
                      info@technoglobalbd.com
                    </p>
                  </div>
                </div>
                <div className="flex lg:gap-4 sm:gap-5 gap-3 items-center">
                  <div className="bg-accent sm:p-5 p-3 rounded-xl">
                    <BiMap className="2xl:text-5xl xl:text-4xl lg:text-2xl sm:text-5xl text-3xl text-primary" />
                  </div>
                  <div>
                    <h4 className="text-secondary 2xl:text-2xl xl:text-lg lg:text-base sm:text-xl font-bold text_raleway">
                      Visit Us
                    </h4>
                    <p className="font-semibold 2xl:text-base lg:text-sm sm:text-lg text-[10px]">
                      BDBL Bhaban 2, Level 6 (East), 12 Kazi Nazrul Islam
                      Avenue, Kawran Bazar, Dhaka 1215.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center justify-items-start 2xl:gap-24 lg:gap-20 sm:gap-28 gap-8">
                <Link to="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    className="fill-secondary"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                  </svg>
                </Link>
                <Link to="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    className="fill-secondary"
                  >
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                  </svg>
                </Link>
                <Link to="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    className="fill-secondary"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gap-4 2xl:px-40 pt-10 flex flex-col">
        <div className="bg-[#f7f7f799] rounded-xl px-7 py-8 shadow">
          <h5 className="pb-3 text-primary font-bold xl:text-3xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
            OUR VISION
          </h5>
          <h3 className="pt-1 font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
            To be the preferred solution provider of choice focused on offering
            energy efficient and cost competitive solutions to our target
            customer base. To deliver to our clients and the community a
            continually expanding range of high-quality services.
          </h3>
        </div>
        <div className="bg-[#f7f7f799] rounded-xl px-7 py-8 shadow">
          <h5 className="pb-3 text-primary font-bold xl:text-3xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
            OUR MISSION
          </h5>
          <h3 className="pt-1 font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
            In all endeavors of the company to act in a character of good faith
            and fairness, and at all times, hold protection of the environment
            in a regard superior to that of all other interests; provide
            superior, innovative and cost efficient bespoke solutions that
            maximize value propositions.
          </h3>
        </div>
        <div className="bg-[#f7f7f799] rounded-xl px-7 py-8 shadow">
          <h5 className="pb-3 text-primary font-bold xl:text-3xl lg:text-lg md:text-xl sm:text-lg text-base text_raleway">
            OUR VALUES
          </h5>
          <h3 className="pt-1 font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
            Our core values will always center on being honest, sincere and
            transparent to both our customer base and our business partners, who
            we both value as equally important and strategic in our aim to
            achieve our Vision. Our five commitments that brings value to our
            clients are
          </h3>
          <div className="flex flex-col sm:gap-1 md:gap-2 xl:gap-3 pt-5">
            <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
              <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
              <h4 className="font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
                Delivering Excellence: Service reliability and availability
              </h4>
            </div>
            <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
              <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
              <h4 className="font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
                Cost Efficiency: Continual improvement
              </h4>
            </div>
            <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
              <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
              <h4 className="font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
                A New Kind of Partnership: Co-creating value through partnership
              </h4>
            </div>
            <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
              <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
              <h4 className="font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
                Empowering Sustainability: Reducing environmental footprint
              </h4>
            </div>
            <div className="flex items-center gap-2 md:gap-3 lg:gap-2 xl:gap-3">
              <BsCaretRightFill className="xl:text-3xl lg:text-xl md:text-2xl sm:text-xl text-lg" />
              <h4 className="font-medium 2xl:text-2xl xl:text-2xl md:text-xl sm:text-base text-sm text_raleway">
                Shaping the Future: Insights and innovations
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Team />
      <Contact />
    </>
  );
};

export default AboutUs;
