import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

import logo from "../Images/Logo/TechnoGlobal.png";

const Footer = () => {
  // EmailJs Login
  // Email: vadoh68382@wifame.com
  // Password: Abced1243#
  const getUpdate = (formData) => {
    emailjs
      .send("Techno Global", "TG Newsletter", formData, "V8Z_q7nVaapM4JCQr")
      .then(
        () => {
          toast.success("Subscribed successfully!", {
            position: "top-right",
          });
          reset();
        },
        () => {
          toast.error("Error! Subscribe again.", {
            position: "top-right",
          });
        }
      );
  };

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <footer className="footer flex flex-col md:flex-row md:flex-wrap md:justify-center lg:justify-between items-center md:justify-items-start lg:items-stretch justify-items-center md:mt-20 sm:mt-16 mt-14 2xl:px-40 xl:px-20 lg:px-10 md:px-20 2xl:py-20 py-14 bg-[#f2f3f6] md:gap-y-5 gap-y-0 md:gap-x-12 gap-x-0">
      <div className="md:justify-items-start lg:items-start items-center justify-items-center xl:gap-1 md:gap-0.5 flex flex-col pb-10 lg:pb-0">
        <span className="footer-title 2xl:text-lg text text_raleway normal-case">
          Subscribe to Get Updates
        </span>
        <div className="form-control w-80">
          <form onSubmit={handleSubmit(getUpdate)}>
            <div className="relative">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                className="input text-primary input-bordered w-full pr-16 shadow-xl"
                {...register("email", {
                  required: {
                    value: true,
                    message: "*Enter an email",
                  },
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "*Please enter a valid email",
                  },
                })}
              />
              <button className="btn absolute top-0 right-0 rounded-l-none normal-case font-semibold text-white text-base bg-secondary border-none hover:border-none hover:bg-[#0e1f51d9] shadow-xl px-5 py-2.5 text_raleway">
                Subscribe
              </button>
            </div>
            <label className="label py-0">
              {errors.email?.type === "required" && (
                <span className="label-text-alt text-error">
                  {errors.email.message}
                </span>
              )}
              {errors.email?.type === "pattern" && (
                <span className="label-text-alt text-error">
                  {errors.email.message}
                </span>
              )}
            </label>
          </form>
        </div>
      </div>
      <div className="lg:hidden justify-center justify-items-center sm:justify-items-stretch gap-0 grid-cols-1 sm:grid-cols-2 md:gap-7 sm:gap-16">
        <div className="grid sm:justify-items-start justify-items-center gap-1 xl:gap-2 pb-1 lg:pb-0">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            Home
          </Link>
          <Link
            to="/about-us"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            About Us
          </Link>
          <Link
            to="/services"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            Our Services
          </Link>
        </div>
        <div className="grid sm:justify-items-end justify-items-center gap-1 xl:gap-2 pb-10 lg:pb-0">
          <Link
            to="/products"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            Products
          </Link>
          <Link
            to="/projects"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            Projects
          </Link>
          <Link
            to="/events"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
          >
            Events
          </Link>
        </div>
      </div>
      <div className="hidden lg:grid gap-1 xl:gap-2 pb-1 lg:pb-0">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          Home
        </Link>
        <Link
          to="/about-us"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          About Us
        </Link>
        <Link
          to="/services"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          Our Services
        </Link>
      </div>
      <div className="hidden lg:grid gap-1 xl:gap-2 pb-10 lg:pb-0">
        <Link
          to="/products"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          Products
        </Link>
        <Link
          to="/projects"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          Projects
        </Link>
        <Link
          to="/events"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="link link-hover 2xl:text-lg text-base font-semibold text_raleway"
        >
          Events
        </Link>
      </div>
      <div className="flex flex-col justify-between items-center">
        <div className="gap-3 flex md:justify-items-start justify-items-center items-center pb-5 lg:pb-0">
          <img className="w-12" src={logo} alt="logo" />
          <div className="flex justify-between flex-col">
            <p className="text_poppins font-extrabold text-[#20262E] 2xl:text-2xl text-2xl 2xl:tracking-wider tracking-wide text_raleway">
              Techno Global Ltd.
            </p>
            <p className="text-sm font-medium text_raleway">
              Copyright © {new Date().getFullYear()} - All right reserved
            </p>
          </div>
        </div>
        <div className="2xl:grid md:justify-items-start justify-items-center">
          <div className="grid grid-flow-col gap-14">
            <Link to="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </Link>
            <Link to="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </Link>
            <Link to="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </Link>
          </div>
          {/* <p className="2xl:text-base text-sm pt-2 text-accent font-medium text_raleway">
          Copyright © {new Date().getFullYear()} - All right reserved
        </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
